import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

export function* getList(action) {
  yield delay(500);
  try {
    const response = {
      items: [
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        }, {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
        {
          "firstName": "John",
          "lastName": "Snow",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipCode": "400325",
          "state": "California",
          "city": "Sacramento",
          "serviceType": "Electrical,AC",
          "openTicketsAmount": "3",
          "mobileNo": "1234567890",
          "email": "sre@serviceProvider.com",
        },
      ],
      meta: {
        totalItems: 5,
        itemCount: 5,
        itemsPerPage: 5,
        totalPages: 5,
        currentPage: 5,
      },
      links: {
        first: '',
        last: '',
        previous: '',
        next: '',
      }
    };
    yield put(actions.getServiceProviderListSuccess(response));
    // console.log(response.items, 'usersList here');
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* getPaymentTable(action) {
  yield delay(500);
  try {
    const responseTable = {
      rows: [
        {
          prefferedPaymentMethod: 'Zelle',
          paymentMethodId: '1320456789',
          checked: true,
        },
        {
          prefferedPaymentMethod: 'Venmo',
          paymentMethodId: 'Enter Venmo Mobile Phone',
          checked: false,
        },
        {
          prefferedPaymentMethod: 'Paypal/Buiseness Account',
          paymentMethodId: 'Enter Paypal Email',
          checked: true,
        },
      ],
    };
    yield put(actions.getPaymentTableSuccess(responseTable));
  } catch (e: any) {
    alert("Something Went Wrong in Payment Table");
  }
}

export function* useServiceProviderSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getPaymentTable.type, getPaymentTable);
  // yield takeLatest(actions.getAllocatedUsers.type, getAllocatedUsers);
}