import { colors, createTheme } from '@mui/material';

const theme = createTheme({
  direction: 'ltr',
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#3753EF',
    },
    secondary: {
      main: "#3C4858",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },

  },
  typography: {
    // fontFamily:'Source Sans 3 sans-serif !important',
    allVariants: {
      textTransform: 'none',
      fontFamily: `"Source Sans 3","SF Pro Display", "Roboto", "Helvetica", "Arial", "Poppins", sans-serif `
    }
  }
});

export default theme;
