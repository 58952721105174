import { FormElement } from 'app/containers/types';

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type AuditLogsListItemType = {
  timeStamp: string | null;
  userId: string | null;
  changeType: string | null;
  changeModule: string | null;
  valueType: string | null;
  oldValue: string | null;
  newValue: string | null;
};

export interface NewAuditLog {
  timeStamp: string | null;
  userId: string | null;
  changeType: string | null;
  changeModule: string | null;
  valueType: string | null;
  oldValue: string | null;
  newValue: string | null;
}

export type NewAuditLogResponse = {
  timeStamp: string | null;
  userId: string | null;
  changeType: string | null;
  changeModule: string | null;
  valueType: string | null;
  oldValue: string | null;
  newValue: string | null; role: any;
  image: any;
  createdAt: string;
  updatedAt: string;
  message?: string;
};

export type AuditLogsForm = {
  timeStamp: FormElement;
  userId: FormElement;
  changeType: FormElement;
  changeModule: FormElement;
  valueType: FormElement;
  oldValue: FormElement;
  newValue: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  blockedUntil: FormElement;
};

export type AuditLogsStateType = {
  impressionsQuery: {
    skip: number;
    limit: number;
    // q: string;
    // status: string;
    // orderField: string;
    // orderDirection: string;
    id: string;
    impressionableId: string;
  };
  postsQuery: {
    page: number;
    limit: number;
    q: string;
    status: string;
    orderField: string;
    orderDirection: string;
    id: string;
  };
  query: {
    limit: number;
    page: number;
    distributorId: string;
    userType: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: {
    log: AuditLogsForm;
  };
  list: {
    items: Array<AuditLogsListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  links: {
    first: string | null,
    previous: string | null,
    next: string | null,
    last: string | null,
  },
  editModal: boolean;
};

export const AuditLogsState: AuditLogsStateType = {
  impressionsQuery: {
    id: '',
    skip: 0,
    limit: 10,
    impressionableId: '',
  },
  postsQuery: {
    id: '',
    page: 1,
    q: '',
    limit: 10,
    orderDirection: '',
    orderField: '',
    status: '',
  },
  query: {
    userType: '',
    distributorId: '',
    limit: 15,
    page: 1,
    q: '',
    orderField: '',
    orderDirection: '',
    updateMax: '',
    updateMin: '',
    createMax: '',
    createMin: '',
  },
  links: {
    first: '',
    previous: '',
    next: '',
    last: '',
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
  },
  loading: false,
  form: {
    log: {
      timeStamp: { value: '' },
      userId: { value: '' },
      changeType: { value: '' },
      changeModule: { value: '' },
      valueType: { value: '' },
      oldValue: { value: '' },
      newValue: { value: '' },
      createdAt: { value: '' },
      updatedAt: { value: '' },
      blockedUntil: { value: '' },
    },
  },
  error: '',
  editModal: false,
};

export type ContainerState = AuditLogsStateType;
