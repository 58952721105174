import Icon from 'app/components/Icons';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Fade, IconButton, Typography } from '@mui/material';

import { selectMenuOpen } from '../../selector';
import NestedLink from '../NestedLink';
import { useStyles } from './styles';
type Props = {
    setShowSidebar: Function
    showSidebar: Boolean
};

const links = [
    {
        parent: 0,
        name: 'userManagement',
        displayName: 'User Management',
        path: '/dashboard/user-management/users',
        icon: <Icon name="UserManagement.svg" />,
        id: 1,
    },
    {
        parent: 0,
        name: 'propertyManagement',
        displayName: 'Property Management',
        path: '/dashboard/property-management/properties',
        icon: <Icon name="property_mgmt.svg" />,
        id: 2,
    },
    {
        parent: 0,
        name: 'serviceRequest',
        displayName: 'Service Request',
        path: '/dashboard/service-request/requests',
        icon: <Icon name="ServiceRequest.svg" />,
        id: 3,
    },
    {
        parent: 0,
        name: 'paymentsTracker',
        displayName: 'Payments Tracker',
        path: '/dashboard/payment-tracker/payments',
        icon: <Icon name="PaymentTracker.svg" />,
        id: 4,
    },
    {
        parent: 0,
        name: 'auditLogs',
        displayName: 'Audit Logs',
        path: '/dashboard/audit-logs/logs',
        icon: <Icon name="AuditLogs.svg" />,
        id: 5,
    },
    {
        parent: 0,
        name: 'serviceProviders',
        displayName: 'Service Providers',
        path: '/dashboard/service-provider/providers',
        icon: <Icon name="ServiceProvider.svg" />,
        id: 6,
    },
];


const Links = () => (
    <Box>
        {links
            .filter(x => x.parent === 0)
            .map(link => (
                // <>{link.name}</>
                <NestedLink key={link.name} {...link} data={links} />
            ))}
    </Box>
);

const Sidebar = ({ setShowSidebar, showSidebar }: Props) => {
    const isMenuOpen = useSelector(selectMenuOpen);
    const closeSideBar = () => {
        setShowSidebar(!showSidebar)
    }
    const styles = useStyles();
    return (
        <>
            <Box
                sx={{ boxShadow: 3 }}
                className={clsx({
                    [styles.root]: true,
                    [styles.menuOpen]: isMenuOpen,
                    [styles.menuClose]: !isMenuOpen,
                })}
                style={{
                    display: showSidebar ? "block" : "none",
                }}
            >
                <Box display={'flex'} justifyContent="center" mt={3.5}>
                    <Icon name="SRELogo.png" />
                </Box>
                <Box className={styles.menuWrapper}>
                    <Fade in={isMenuOpen} timeout={{ enter: 300, exit: 200 }}>
                        <span className={styles.abovelinksWrap}>
                            {isMenuOpen && <Links />}
                        </span>
                    </Fade>

                </Box>
            </Box >
            <IconButton onClick={closeSideBar}
                sx={{
                    position: "fixed",
                    top: "5px",
                    left: showSidebar ? "220px" : 0,
                    zIndex: 11111,
                    color: "#CCCDCF",

                }}>
                {showSidebar ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
            </IconButton>
        </>
    );
};

export default Sidebar;
