import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { actions } from '../../slice';

type Props = {
  name: string;
  path: string;
  target?: string;
  icon?: any;
  isDifferent?: boolean;
  end?: boolean;
  names?: object[];
  displayName?: string;
  disabled?: boolean;
  data: Array<any>;
  id: number | string;
  parent: number | string;
};

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 4,
    textDecoration: 'none',
    color: '#3C4858',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  active: {
    borderRadius: 6,
    // boxShadow: '0px 4px 4px rgba(239, 99, 40, 0.35)',
    background: '#eeeeee',
    color: '#FAA61A !important',
    '& *': {
      color: '#000000 !important',
    },
    '& img': {
      filter: 'brightness(1) invert(100%) saturate(4) hue-rotate(180deg)',
    },
  },
  icon: {
    color: '#8F8E8E',
    marginRight: 18,
    background: 'transparent',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    width: '100%',
    // backgroundColor: "#333",
  },
  isDifferent: {
    paddingLeft: 17,
  },
  isSettingDropDown: {
    height: '40px',
    marginLeft: '30px',
  },
  diffIcon: {
    // marginRight: 18,
  },
  link: {
    background: 'transparent',
  },
  parent: {
    padding: '14px 20px',
  },
  accordionContainer: {
    '&.MuiPaper-root.MuiAccordion-root': {
      margin: 0,
    },
    '&.MuiPaper-root.MuiAccordion-root:before': {
      display: 'none',
      backgroundColor: 'transparent!important',
    },

  },
  accordionSummary: {
    margin: 0,
    backgroundColor: '#fff',
    '&.Mui-expanded': {
      margin: 0,
    }
  },
  expandedAccordion: {
    color: '#FAA61A'
  },
  sideBarItemsText:{

  },
}));

const AccordinComponent = props => {
  const { data, name, icon, displayName, disabled, end, id } = props;
  const styles = useStyles();
  const links = data.filter(link => link.parent === id);
  return (
    <Accordion
      className={styles.accordionContainer}
      elevation={0}
      sx={{ backgroundColor: '#fff', border: 0 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{ content: styles.accordionSummary, expanded: styles.expandedAccordion }}
        sx={{

          margin: 0,
        }}
        style={{
          justifyContent: 'space-between',
          backgroundColor: 'transparent',

        }}
      >
        <div
          className={clsx({
            [styles.icon]: true,
            [styles.diffIcon]: true,
          })}

        >
          {icon && <>{icon}</>}
        </div>
        <Typography sx={{ mr: 6 }}>
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ py: 0, }}>
        {links.map(x => (
          <NestedLink key={x.id} {...x} data={data} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const NestedLink = (props: Props) => {
  const { name,displayName, data, id, path, disabled, icon, target, parent } = props;
  const dispatch = useDispatch();
  const links = data.filter(link => link.parent === id);
  const toggle = links.length > 0;
  const styles = useStyles();
  return (
    <>
      {toggle ? (
        <>
          {/* <NavLink to={path}> */}
          <AccordinComponent key={id} {...props} />
        </>
      ) :
        disabled ? (
          <div
            className={clsx({
              [styles.root]: true,
            })}
          >
            <MenuItem
              disabled={disabled}
              className={clsx({
                [styles.wrapper]: true,
                [styles.isDifferent]: false,
              })}

            >
              <div
                className={clsx({
                  [styles.icon]: true,
                  [styles.diffIcon]: false,
                })}

              >
                {icon && <>{icon}</>}
              </div>
              <Typography sx={{ color: '#8F8E8E' }}></Typography>
            </MenuItem>
          </div>
        ) : (
          <NavLink
            target={target}
            to={disabled ? '/dashboard/' : path}
            className={prop => {
              return clsx({
                [styles.root]: true,
                [styles.active]: prop.isActive,
              });
            }}
            onClick={() => dispatch(actions.setSearch(''))}
          >
            <MenuItem
              className={clsx({
                [styles.wrapper]: true,
                [styles.isDifferent]: true,
                [styles.parent]: parent === 0,
              })}
            >
              <div
                className={clsx({
                  [styles.icon]: true,
                  [styles.diffIcon]: true,
                })}
              >
                {icon}
              </div>
              <Typography >{displayName}</Typography>
            </MenuItem>
          </NavLink>
        )}
    </>
  );
};

export default NestedLink;
