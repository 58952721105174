import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Checkbox,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { selectError, selectLoading, selectLoginForm } from '../selectors';
import { actions } from '../slice';
import { LoginErrorType } from '../types';
import { validate } from './validator';
const useStyles = makeStyles((theme: Theme) => ({
  loginButtonWrapper: {},
  form: {},
  loginText: {
    textAlign: 'center',
    marginBottom: '30px',
    // marginTop: '160px',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '38px',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  error: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  keepSignedin: {
    marginTop: "24px",
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '10px',
    color: '#333333',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  submit: {
    width: '414px',
    height: 56,
    fontSize: 22,
    fontWeight: 500,
    borderRadius: 6,
    color: 'fff',
    marginTop: "57px",
  },
  emailLabel: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '10px',
    marginBottom:'12px',
    color: '#333333',
  },
  email: {
    marginTop: 0,
    marginBottom: 23,
    borderRadius: 6,
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7FA inset !important",
      backgroundColor: "transparent",
      backgroundImage: "none !important",
      color: "#000000 !important",
    },
    '& .MuiFormControl-root': {},
    '& .MuiOutlinedInput-input': {},
    '& .MuiOutlinedInput-root': {
      width: '414px',
      backgroundColor: "#F7F7FA",
      '&.Mui-focused fieldset': {
        borderColor: '#c4c4c4',
      },
      '&:hover fieldset': {
        borderColor: '#c4c4c4',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '6px',
    },
  },
  password: {
    margin: 0,
    borderRadius: 6,
    "& input:-internal-autofill-selected": {
      backgroundColor: "#F7F7FA"
    },
    "& input:-webkit-autofill": {
      "-webkit-box-shadow": "0 0 0 30px #F7F7FA inset !important",
      backgroundColor: "transparent",
      backgroundImage: "none !important",
      color: "#000000 !important",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '6px',
    },
    '& .MuiFormControl-root': {},
    '& .MuiOutlinedInput-input': {},
    '& .MuiOutlinedInput-root': {
      width: '414px',
      backgroundColor: "#F7F7FA",
      '&.Mui-focused fieldset': {
        borderColor: '#c4c4c4',
      },
      '&:hover fieldset': {
        borderColor: '#c4c4c4',
      },
    },
  },
  copyRightText: {
    fontSize: 14,
    color: '#00000062',
    textAlign: 'center',
    position: 'absolute',
  },
}));

export function LoginForm({ className }) {
  const classes = useStyles();
  const form = useSelector(selectLoginForm);
  const isLoading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [visible, setVisible] = useState(false);

  const onLoginSuccess = () => {
    navigate('/dashboard/user-management/users');
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(actions.setForm({ key: `loginForm.${name}`, value }));
  };

  const onSubmitForm = (evt?: React.FormEvent<HTMLFormElement>) => {
    try {
      evt?.preventDefault();
      setErrorMsg('');
      const errors = validate(form);
      if (errors.length > 0) {
        dispatch(actions.setFormErrors({ key: 'loginForm', errors }));
        return;
      }
      dispatch(actions.login({ callback: onLoginSuccess }));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (error) setErrorMsg(error);
    // eslint-disable-next-line
  }, [error]);

  const errorText = errorMsg;
  return (
    <form className={classes.form} noValidate onSubmit={onSubmitForm}>
      {errorText && (
        <Alert className={classes.error} severity="error">
          {errorText}
        </Alert>
      )}
      <Typography className={classes.loginText} gutterBottom>
        Login
      </Typography>
      <div dir="ltr">
        <Typography className={classes.emailLabel}>Email</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          placeholder="Email Id"
          name="username"
          autoComplete={"off"}
          error={!!form.username.error}
          helperText={form.username?.error}
          autoFocus
          value={form.username.value}
          onChange={onChange}
          className={classes.email}
        />
        <Typography className={classes.emailLabel} >Password</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder="Password"
          type={visible ? 'text' : 'password'}
          id="password"
          value={form.password.value}
          onChange={onChange}
          error={!!form.password.error}
          helperText={form.password?.error}
          autoComplete={"off"}
          className={classes.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span
                  onClick={() => setVisible(!visible)}
                  style={{ cursor: 'pointer' }}
                >
                  <IconButton sx={{ color: '#D1D0D0' }}>
                    {visible ? (
                      <VisibilityOff sx={{ color: '#D1D0D0' }} />
                    ) : (
                      <Visibility sx={{ color: '#D1D0D0' }} />
                    )}
                  </IconButton>
                </span>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Box className={classes.keepSignedin}>
        <Checkbox sx={{ padding: "0" }} /> Remember me
      </Box>
      <div className={classes.loginButtonWrapper}>
        <Button
          variant="contained"
          className={classes.submit}
          type="submit"
        >
          {isLoading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : "Login"}
        </Button>

      </div>
    </form>
  );
}
export const loginError = (error: LoginErrorType | undefined | null) => {
  if (!error) return null;
  switch (error) {
    case LoginErrorType.ERROR:
      return {
        message: 'An error has occurred! 😞',
        username: true,
        password: true,
      };
    case LoginErrorType.USERNAME_EMPTY:
      return { message: 'Please enter username', username: true };
    case LoginErrorType.PASSWORD_EMPTY:
      return { message: 'Please enter password', password: true };
    default:
      return { message: error, password: true, username: true };
  }
};
export default LoginForm;
