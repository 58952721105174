import { FormError } from 'app/containers/types';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import { EDIT_USER_ROLES, UserTypeEnum } from 'utils/UserType.enum';

export const handleChange = (event, state, stateUpdater) => {
  const { name, value } = event.target;

  if (typeof state === 'object') {
    stateUpdater({ ...state, [name]: value });
  } else {
    stateUpdater(value);
  }
};

export const emailValidator = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getFormattedDateTime = (
  date: any,
  format: string = 'dd-MM-yyyy, hh:mm a',
) => {
  if (!date) {
    return '-';
  }
  return DateTime.fromISO(date).toFormat(format);
};

export const getLogTime = (
  date: any,
  format: string = 'dd-MM-yyyy, hh:mm:ss.SSS a',
) => {
  if (!date) {
    return '-';
  }
  return DateTime.fromMillis(date).toFormat(format);
};

export const getDefaultHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
  };
};

export const removeBlankProperties = (obj: object) => {
  return JSON.parse(JSON.stringify(obj, replaceUndefinedOrNull));
};

export function replaceUndefinedOrNull(key, value) {
  if (value === null || value === undefined || value === '') {
    return undefined;
  }

  return value;
}

/**
 * returns logged in user is
 * @param
 */

export function formatDuration(duration) {
  if (parseFloat(duration) > 0) {
    return (parseFloat(duration) / 60).toFixed(2) + ' min';
  }
  return '-';
}
export function formatBytes(bytes, decimals = 2) {
  if (bytes == 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // console.log({ i });

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const dateFormatter = unformattedDate => {
  if (!unformattedDate) {
    return '-';
  }
  let formatDate = new Date(unformattedDate);
  const newDate = format(formatDate, 'dd/MM/yyyy');
  return newDate;
};

export const truncate = (str, lmt?) => {
  return str.length > lmt ?? 20
    ? str.substring(0, lmt ? lmt - 5 : 15) + '...'
    : str;
};

export const validateSquareImageDimensions = (
  file: File,
  errorKey: string = 'image.error',
  minWidth: number = 300,
  minHeight: number = 300,
  maxWidth: number = 1920,
  maxHeight: number = 1080,
): Promise<any> => {
  // const maxHeight = height * 3;
  // const maxWidth = width * 3;
  const result: Array<FormError> = [];
  return new Promise((resolve, _) => {
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        console.log(image.width, image.height);
        if (image.height >= minHeight && image.height <= maxHeight) {
          console.log(
            'yes 123',
            image.height >= minHeight,
            image.height <= maxHeight,
            image.width / image.height,
            minWidth / minHeight,
          );
          if (image.width / image.height === minWidth / minHeight) {
            console.log(
              image.height > minHeight,
              'min height',
              image.width / image.height,
              minWidth / minHeight,
            );
            console.log(image.height > minHeight, 'max height');
            resolve(true);
          } else {
            result.push({
              name: errorKey,
              error: `Image should have minimum ${minHeight}px width, ${minHeight}px height and maximum ${maxWidth}px width, ${maxHeight}px height`,
            });
            resolve(result);
          }
        } else {
          result.push({
            name: errorKey,
            error: `Image should have minimum ${minWidth}px width, ${minHeight}px height and maximum ${maxWidth}px width, ${maxHeight}px height`,
          });
          resolve(result);
        }
      };
    } else {
      resolve(false);
    }
  });
};

export const validateImageDimensions = (
  file: File,
  name: string = 'video.error',
  width: number = 300,
  height: number = 300,
  maxWidth: number = 1920,
  maxHeight: number = 1080,
  validateAspectRatio: boolean = false,
): Promise<any> => {
  const result: Array<FormError> = [];
  return new Promise((resolve, _) => {
    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        console.log(image.width, image.height);
        if (
          image.height >= height &&
          image.height <= maxHeight &&
          image.width >= width &&
          image.width <= maxWidth
        ) {
          if (!validateAspectRatio) {
            resolve(true);
          } else {
            if (
              (image.width / image.height).toFixed(2) ===
              (width / height).toFixed(2)
            ) {
              resolve(true);
            } else {
              result.push({
                name,
                error: `Image should have minimum 1920px width, 1080px height and maximum ${maxWidth}px width, ${maxHeight}px height`,
              });
              resolve(result);
            }
          }
        } else {
          result.push({
            name,
            error: `Image should have minimum ${width}px width, ${height}px height and maximum ${maxWidth}px width, ${maxHeight}px height`,
          });
          resolve(result);
        }
      };
    } else {
      resolve(false);
    }
  });
};