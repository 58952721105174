import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

export function* getList(action) {
  yield delay(500);
  try {
    const response = {
      items: [
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        }, {
          "timeStamp": "07-11-2022, 7:30AM",
          "payer": "U410142",
          "payee": "U410142",
          "propertyId": "P410142",
          "amount": "500$",
          "paymentType": "Rent",
          "paymentGateway": "Paypal",
          "approvalId": "AP12345",
        },

      ],
      meta: {
        totalItems: 5,
        itemCount: 5,
        itemsPerPage: 5,
        totalPages: 5,
        currentPage: 5,
      },
      links: {
        first: '',
        last: '',
        previous: '',
        next: '',
      }
    }
    yield put(actions.getPaymentsListSuccess(response));
    // console.log(response.items, 'usersList here');
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* usePaymentTrackerSaga() {
  yield takeLatest(actions.getList.type, getList);
  // yield takeLatest(actions.getAllocatedUsers.type, getAllocatedUsers);
}