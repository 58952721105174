import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

export function* getList(action) {
  yield delay(500);
  try {
    const response = {
      items: [
        {
          "propertyId": "P410142",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "California",
          "city": "Sacramento",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Ok",
          "email": "john snow@gmail.com"
        },
        {
          "propertyId": "P410142",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Colorado",
          "city": "Denver",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Select time frame",
          "email": "johnsnow @gmail.com"
        },
        {
          "propertyId": "P410142",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Connecticut",
          "city": "Hartford",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Code",
          "email": "johnsnow @gmail.com"
        },
        {
          "propertyId": "P410142",
          "address": "1539 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Florida",
          "city": "Tallahassee",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Approval",
          "email": "johnsno w@gmail.com"
        },
        {
          "propertyId": "P410143",
          "address": "1550 Keith Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "California",
          "city": "Sacramento",
          "type": "Multi-unit",
          "occupationStatus": "Owner Occuiped",
          "propertyStatus": "Ok",
          "email": "aryastark@gmail.com"
        },
        {
          "propertyId": "P410143",
          "address": "1550 Keith Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Colorado",
          "city": "Denver",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Select time frame",
          "email": "aryaStark@gmail.com"
        },
        {
          "propertyId": "P410143",
          "address": "1550 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Connecticut",
          "city": "Hartford",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Code",
          "email": "aryaStark@gmail.com"
        },
        {
          "propertyId": "P410143",
          "address": "1550 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Florida",
          "city": "Tallahassee",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Approval",
          "email": "aryaStark@gmail.com"
        },
        {
          "propertyId": "P410143",
          "address": "1550 Raoul Wallenberg Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Hartford",
          "city": "Tallahassee",
          "type": "Multi-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Approval",
          "email": "aryaStark@gmail.com"
        },
        {
          "propertyId": "P410144",
          "address": "1650 Raoul Place, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Hartford",
          "city": "Tallahassee",
          "type": "Single-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Approval",
          "email": "cerseiLennister@gmail.com"
        },
        {
          "propertyId": "P410145",
          "address": "1660 Richards Villa, Near Keith Circle",
          "zipcode": 35004,
          "state": "Hartford",
          "city": "Tallahassee",
          "type": "Single-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Approval",
          "email": "brian@gmail.com"
        },
        {
          "propertyId": "P410146",
          "address": "1670 South Palace, Near Park Arear Milford",
          "zipcode": 35004,
          "state": "Hartford",
          "city": "Tallahassee",
          "type": "Single-unit",
          "occupationStatus": "Tenant Occuiped",
          "propertyStatus": "Pending Approval",
          "email": "tarley@gmail.com"
        }
      ],
      meta: {
        totalItems: 5,
        itemCount: 5,
        itemsPerPage: 5,
        totalPages: 5,
        currentPage: 5,
      },
      links: {
        first: '',
        last: '',
        previous: '',
        next: '',
      }
    }
    console.log('from saga getlist 2');
    yield put(actions.getPropertiesListSuccess(response));
    // console.log(response.items, 'usersList here');
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* getAllocatedUsers(action) {
  yield delay(5000);
  try {
    const response = {
      users : [
        {
          id: 'U410142',
          firstName: 'Yan',
          lastName: 'Agron',
          email: 'yan agron@gmail.com',
          mobileNumber: 14842634717,
        },
        {
          id: 'U410143',
          firstName: 'Matt',
          lastName: 'Smith',
          email: 'Matsmith@gmail.com',
          mobileNumber: 14844731349,
        },
        {
          id: 'U410144',
          firstName: 'Peter',
          lastName: 'Agron',
          email: 'Peteragron@gmail.com',
          mobileNumber: 18143519530,
        },
        {
          id: 'U410145',
          firstName: 'Suzan',
          lastName: 'Back',
          email: 'Suzanback@gmail.com',
          mobileNumber: 14845211058,
        },
        {
          id: 'U410142',
          firstName: 'Yan',
          lastName: 'Agron',
          email: 'yan agron@gmail.com',
          mobileNumber: 14842634717,
        },
        {
          id: 'U410143',
          firstName: 'Matt',
          lastName: 'Smith',
          email: 'Matsmith@gmail.com',
          mobileNumber: 14844731349,
        },
        {
          id: 'U410144',
          firstName: 'Peter',
          lastName: 'Agron',
          email: 'Peteragron@gmail.com',
          mobileNumber: 18143519530,
        },
        {
          id: 'U410145',
          firstName: 'Suzan',
          lastName: 'Back',
          email: 'Suzanback@gmail.com',
          mobileNumber: 14845211058,
        }, {
          id: 'U410142',
          firstName: 'Yan',
          lastName: 'Agron',
          email: 'yan agron@gmail.com',
          mobileNumber: 14842634717,
        },
        {
          id: 'U410143',
          firstName: 'Matt',
          lastName: 'Smith',
          email: 'Matsmith@gmail.com',
          mobileNumber: 14844731349,
        },
        {
          id: 'U410144',
          firstName: 'Peter',
          lastName: 'Agron',
          email: 'Peteragron@gmail.com',
          mobileNumber: 18143519530,
        },
        {
          id: 'U410145',
          firstName: 'Suzan',
          lastName: 'Back',
          email: 'Suzanback@gmail.com',
          mobileNumber: 14845211058,
        },
      ],
    }
    yield put(actions.getAllocatedUsersSuccess(response));
  } catch (e: any) {
    alert("Unable to get Allocated Users");
  }
}

export function* usePropertySaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getAllocatedUsers.type, getAllocatedUsers);
}