import React from 'react';

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export const LoadingIndicator = (props: CircularProgressProps) => (
  <Box sx={{ position: 'absolute', top: '50%', left:'50%'}}>
    <CircularProgress size={'3rem'} {...props} />
  </Box>
);
