import { FormError } from 'app/containers/types';
import set from 'lodash/set';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { PayloadAction } from '@reduxjs/toolkit';

import { PropertyListItemType, PropertyState, AllocatedUsers } from '../types';

const initialState = PropertyState;

export const propertySlice = createSlice(
  {
    name: 'propertyState',
    initialState,
    reducers: {
      editProperty: (state, action: PayloadAction<PropertyListItemType>) => {
        state.form.property.id.value = action.payload.id;
        state.form.property.email.value = action.payload.email;
      },
      removeProperty: state => {
        const editProperty: any = {};
        editProperty.address = '';
        editProperty.zipCode = 0;
        editProperty.state = '';
        editProperty.city = '';
        editProperty.type = '';
        editProperty.occupationStatus = '';
        editProperty.propertyStatus = '';
        editProperty.email = '';
      },
      create: (state, action) => {
        state.loading = true;
      },
      createSuccess: state => {
        state.loading = false;
        state.form.property = initialState.form.property;
      },
      getList: state => {
        state.loading = true;
      },
      getAllocatedUsers: state => {
        state.loading = true;
      },
      setEditModal: (state, action: PayloadAction<boolean>) => {
        state.editModal = action.payload;
      },
      getPropertyById: (state, action: PayloadAction<{ id: any }>) => {
        state.loading = true;
      },
      getPropertiesListSuccess: (
        state,
        action: PayloadAction<{ items: any; links: any; meta: any }>,
      ) => {
        state.loading = false;
        state.list.items = action.payload.items;
        state.list.meta = action.payload.meta;
        state.list.links = action.payload.links;
      },
      getAllocatedUsersSuccess: (
        state,
        action: PayloadAction<{ users: any }>,
      ) => {
        state.loading = false;
        console.log(action.payload.users);
        state.allocatedUsers = action.payload.users;
      },
      getNextPage: (state, action: PayloadAction<any>) => {
        // console.log(action.payload, 'action.payload get next page');
        // state.loading = false;
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
        state.loading = action.payload;
      },
      update: (
        state,
        action: PayloadAction<{ id: any; callback: () => void }>,
      ) => {
        state.error = '';
        state.loading = true;
      },
      updateFormValue: (
        state,
        action: PayloadAction<{ key: string; value: any }>,
      ) => {
        set(state.form, `${action.payload.key}.value`, action.payload.value);
        set(state.form, `${action.payload.key}.error`, null);
        state.error = '';
      },
      updateSuccess(state, action: PayloadAction<PropertyListItemType>) {
        state.loading = false;
        const index = state.list.items.findIndex(
          item => item.id === action.payload.id,
        );
        if (index >= 0) {
          state.list.items[index] = action.payload;
        }
      },
      updateFailed: (state, action) => {
        state.error = action.payload;
        state.loading = false;
      },
      delete: (state, action) => { },
      deleteSuccess: (state, action) => {
        // console.log("state", state)
        // console.log("actoion11", action)
        state.list.items = state.list.items.filter(
          item => item.id !== action.payload,
        );
      },
      deleteFailed: (state, action) => {
        state.error = action.payload;
        state.loading = false;
      },
      resetNewPropertyForm(state) {
        state.error = '';
        state.form = initialState.form;
      },
      setFormErrors(
        state,
        action: PayloadAction<{
          key: string;
          errors: FormError[];
        }>,
      ) {
        action.payload.errors.forEach(error => {
          set(
            state.form,
            `${action.payload.key}.${error.name}.error`,
            error.error,
          );
        });
      },
      getNextPageItems: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.list.items = state.list.items.concat(action.payload.items);
        state.list.meta.currentPage = action.payload.meta.currentPage;
        state.list.links = action.payload.links;
        state.query.page = action.payload.meta.currentPage;
      },
      setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
        const { name, value } = action.payload;
        state.query.page = 1;
        state.list = initialState.list;
        set(state.query, name, value);
      },
      resetModule: state => {
        state = initialState;
      },
      handleBlock: (state, action: PayloadAction<string>) => { },
      // handleBlockSuccess: (state, action: PayloadAction<PropertyListItemType>) => {
      //   const index = state.list.items.findIndex(u => u.id == action.payload.id);
      //   if (index > -1) {
      //     state.list.items[index].isActive = action.payload.isActive;
      //     state.list.items[index].updatedAt = action.payload.updatedAt;
      //   }
      // },
    },
  });

export const { reducer, actions, name: sliceKey } = propertySlice;
