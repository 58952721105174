import React from 'react'
import './style.css'
import "./style.css"
import ReactMarkdown from 'react-markdown'
import { useLocation } from 'react-router-dom'
type Props = {}

const PrivacyPolicy = (props: Props) => {
    const location = useLocation()
    return (
        <div>
            <h3 style={{ textAlign: "center" }}> Privacy & Policy</h3>
            <div className='center_div'>
                <div className='right_side'>
                    {/* @ts-ignore */}
                    <ReactMarkdown children={location?.state?.data} />
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy