import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

export function* getList(action) {
  yield delay(500);
  try {
    const response = {
      items: [
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Tenant",
          "category": "Plumbing",
          "status": "New"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Landlord",
          "category": "Handyman",
          "status": "In Progress"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Tenant",
          "category": "Plumbing",
          "status": "New"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Landlord",
          "category": "Handyman",
          "status": "In Progress"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Tenant",
          "category": "Plumbing",
          "status": "New"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Landlord",
          "category": "Handyman",
          "status": "In Progress"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Tenant",
          "category": "Plumbing",
          "status": "New"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Landlord",
          "category": "Handyman",
          "status": "In Progress"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Tenant",
          "category": "Plumbing",
          "status": "New"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Landlord",
          "category": "Handyman",
          "status": "In Progress"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Tenant",
          "category": "Plumbing",
          "status": "New"
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "id": "S210142",
          "propertyId": "P410142",
          "ticketUpdates": "Kitchen Tap spills water on the floor when its open",
          "address": "1539 Raoul Wallenberg Place, Miller Road, Southern Street",
          "userId": "U410142",
          "serviceProviderId": "S410142",
          "userType": "Landlord",
          "category": "Handyman",
          "status": "In Progress"
        }
      ],
      meta: {
        totalItems: 5,
        itemCount: 5,
        itemsPerPage: 5,
        totalPages: 5,
        currentPage: 5,
      },
      links: {
        first: '',
        last: '',
        previous: '',
        next: '',
      }
    }
    console.log('from saga getlist 2');
    yield put(actions.getServiceRequestsListSuccess(response));
    // console.log(response.items, 'usersList here');
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

// export function* getAllocatedUsers(action) {
//   yield delay(5000);
//   try {
//     const response = {
//       users : [
//         {
//           id: 'U410142',
//           firstName: 'Yan',
//           lastName: 'Agron',
//           email: 'yan agron@gmail.com',
//           mobileNumber: 14842634717,
//         },
//         {
//           id: 'U410143',
//           firstName: 'Matt',
//           lastName: 'Smith',
//           email: 'Matsmith@gmail.com',
//           mobileNumber: 14844731349,
//         },
//         {
//           id: 'U410144',
//           firstName: 'Peter',
//           lastName: 'Agron',
//           email: 'Peteragron@gmail.com',
//           mobileNumber: 18143519530,
//         },
//         {
//           id: 'U410145',
//           firstName: 'Suzan',
//           lastName: 'Back',
//           email: 'Suzanback@gmail.com',
//           mobileNumber: 14845211058,
//         },
//         {
//           id: 'U410142',
//           firstName: 'Yan',
//           lastName: 'Agron',
//           email: 'yan agron@gmail.com',
//           mobileNumber: 14842634717,
//         },
//         {
//           id: 'U410143',
//           firstName: 'Matt',
//           lastName: 'Smith',
//           email: 'Matsmith@gmail.com',
//           mobileNumber: 14844731349,
//         },
//         {
//           id: 'U410144',
//           firstName: 'Peter',
//           lastName: 'Agron',
//           email: 'Peteragron@gmail.com',
//           mobileNumber: 18143519530,
//         },
//         {
//           id: 'U410145',
//           firstName: 'Suzan',
//           lastName: 'Back',
//           email: 'Suzanback@gmail.com',
//           mobileNumber: 14845211058,
//         }, {
//           id: 'U410142',
//           firstName: 'Yan',
//           lastName: 'Agron',
//           email: 'yan agron@gmail.com',
//           mobileNumber: 14842634717,
//         },
//         {
//           id: 'U410143',
//           firstName: 'Matt',
//           lastName: 'Smith',
//           email: 'Matsmith@gmail.com',
//           mobileNumber: 14844731349,
//         },
//         {
//           id: 'U410144',
//           firstName: 'Peter',
//           lastName: 'Agron',
//           email: 'Peteragron@gmail.com',
//           mobileNumber: 18143519530,
//         },
//         {
//           id: 'U410145',
//           firstName: 'Suzan',
//           lastName: 'Back',
//           email: 'Suzanback@gmail.com',
//           mobileNumber: 14845211058,
//         },
//       ],
//     }
//     yield put(actions.getAllocatedUsersSuccess(response));
//   } catch (e: any) {
//     alert("Unable to get Allocated Users");
//   }
// }

export function* useServiceRequestSaga() {
  yield takeLatest(actions.getList.type, getList);
  // yield takeLatest(actions.getAllocatedUsers.type, getAllocatedUsers);
}