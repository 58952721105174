import { FormElement } from 'app/containers/types';

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type ServiceProviderListItemType = {
  firstName: string | null;
  lastName: string | null;
  address: string | null;
  zipCode: string | null;
  state: string | null;
  city: string | null;
  serviceType: string | null;
  openTicketsAmount: string | null;
  mobileNum: string | null;
  email: string | null;
};

export interface PaymentRow {
  prefferedPaymentMethod: string | null;
  paymentMethodId: string | null;
  checked: Boolean,
}
export interface NewServiceProvider {
  firstName: string | null;
  lastName: string | null;
  address: string | null;
  zipCode: string | null;
  state: string | null;
  city: string | null;
  serviceType: string | null;
  openTicketsAmount: string | null;
  mobileNum: string | null;
  email: string | null;
}

export type NewServiceProviderResponse = {
  firstName: string | null;
  lastName: string | null;
  address: string | null;
  zipCode: string | null;
  state: string | null;
  city: string | null;
  serviceType: string | null;
  openTicketsAmount: string | null;
  mobileNum: string | null;
  email: string | null;
  image: any;
  createdAt: string;
  updatedAt: string;
  message?: string;
};

export type ServiceProviderForm = {
  firstName: FormElement;
  lastName: FormElement;
  address: FormElement;
  zipCode: FormElement;
  state: FormElement;
  city: FormElement;
  serviceType: FormElement;
  openTicketsAmount: FormElement;
  mobileNum: FormElement;
  email: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  blockedUntil: FormElement;
};

export type ServiceProviderStateType = {
  impressionsQuery: {
    skip: number;
    limit: number;
    // q: string;
    // status: string;
    // orderField: string;
    // orderDirection: string;
    id: string;
    impressionableId: string;
  };
  postsQuery: {
    page: number;
    limit: number;
    q: string;
    status: string;
    orderField: string;
    orderDirection: string;
    id: string;
  };
  query: {
    limit: number;
    page: number;
    distributorId: string;
    userType: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: {
    log: ServiceProviderForm;
  };

  paymentTable: {
    rows: Array<PaymentRow>;
  };

  list: {
    items: Array<ServiceProviderListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  links: {
    first: string | null,
    previous: string | null,
    next: string | null,
    last: string | null,
  },
  editModal: boolean;
};

export const ServiceProviderState: ServiceProviderStateType = {
  impressionsQuery: {
    id: '',
    skip: 0,
    limit: 10,
    impressionableId: '',
  },
  postsQuery: {
    id: '',
    page: 1,
    q: '',
    limit: 10,
    orderDirection: '',
    orderField: '',
    status: '',
  },
  query: {
    userType: '',
    distributorId: '',
    limit: 15,
    page: 1,
    q: '',
    orderField: '',
    orderDirection: '',
    updateMax: '',
    updateMin: '',
    createMax: '',
    createMin: '',
  },
  links: {
    first: '',
    previous: '',
    next: '',
    last: '',
  },
  paymentTable: {
    rows: [],
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
  },
  loading: false,
  form: {
    log: {
      firstName: { value: '' },
      lastName: { value: '' },
      address: { value: '' },
      zipCode: { value: '' },
      state: { value: '' },
      city: { value: '' },
      serviceType: { value: '' },
      openTicketsAmount: { value: '' },
      mobileNum: { value: '' },
      email: { value: '' },
      createdAt: { value: '' },
      updatedAt: { value: '' },
      blockedUntil: { value: '' },
    },
  },
  error: '',
  editModal: false,
};

export type ContainerState = ServiceProviderStateType;
