import { FormElement } from 'app/containers/types';

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type PaymentTrackerListItemType = {
  timeStamp: string | null;
  payer: string | null;
  payee: string | null;
  propertyId: string | null;
  amount: string | null;
  paymentType: string | null;
  paymentGateway: string | null;
  approvalId: string | null;
};

export interface NewPaymentTracker {
  timeStamp: string | null;
  payer: string | null;
  payee: string | null;
  propertyId: string | null;
  amount: string | null;
  paymentType: string | null;
  paymentGateway: string | null;
  approvalId: string | null;
}

export type NewPaymentTrackerResponse = {
  timeStamp: string | null;
  payer: string | null;
  payee: string | null;
  propertyId: string | null;
  amount: string | null;
  paymentType: string | null;
  paymentGateway: string | null;
  approvalId: string | null;
  role: any;
  image: any;
  createdAt: string;
  updatedAt: string;
  message?: string;
};

export type PaymentTrackerForm = {
  timeStamp: FormElement;
  payer: FormElement;
  payee: FormElement;
  propertyId: FormElement;
  amount: FormElement;
  paymentType: FormElement;
  paymentGateway: FormElement;
  approvalId: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  blockedUntil: FormElement;
};

export type PaymentTrackerStateType = {
  impressionsQuery: {
    skip: number;
    limit: number;
    // q: string;
    // status: string;
    // orderField: string;
    // orderDirection: string;
    id: string;
    impressionableId: string;
  };
  postsQuery: {
    page: number;
    limit: number;
    q: string;
    status: string;
    orderField: string;
    orderDirection: string;
    id: string;
  };
  query: {
    limit: number;
    page: number;
    distributorId: string;
    userType: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: {
    payment: PaymentTrackerForm;
  };
  list: {
    items: Array<PaymentTrackerListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  links: {
    first: string | null,
    previous: string | null,
    next: string | null,
    last: string | null,
  },
  editModal: boolean;
};

export const PaymentTrackerState: PaymentTrackerStateType = {
  impressionsQuery: {
    id: '',
    skip: 0,
    limit: 10,
    impressionableId: '',
  },
  postsQuery: {
    id: '',
    page: 1,
    q: '',
    limit: 10,
    orderDirection: '',
    orderField: '',
    status: '',
  },
  query: {
    userType: '',
    distributorId: '',
    limit: 15,
    page: 1,
    q: '',
    orderField: '',
    orderDirection: '',
    updateMax: '',
    updateMin: '',
    createMax: '',
    createMin: '',
  },
  links: {
    first: '',
    previous: '',
    next: '',
    last: '',
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
  },
  loading: false,
  form: {
    payment: {
      timeStamp: { value: '' },
      payer: { value: '' },
      payee: { value: '' },
      propertyId: { value: '' },
      amount: { value: '' },
      paymentType: { value: '' },
      paymentGateway: { value: '' },
      approvalId: { value: '' },
      createdAt: { value: '' },
      updatedAt: { value: '' },
      blockedUntil: { value: '' },
    },
  },
  error: '',
  editModal: false,
};

export type ContainerState = PaymentTrackerStateType;
