import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_MENU_WIDTH } from 'utils/constants';

import { TextField } from 'app/components/TextField';
import SearchIcon from '../topbar/assets/search.png';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Avatar, IconButton, MenuItem, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

import { selectCurrentRoute, selectMenuOpen } from '../../selector';
import Profile from './assets/profile.png';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    height: 74,
    zIndex: 9,
    background: '#fff',
    transition: `.3s`,
    // paddingLeft: "10px"
    // width: '1000px',
  },
  wrapper: {
    padding: '0px 94px 0px 56px',
    height: '100%',
  },
  hr: {
    margin: 0,
    // marginTop: 21,
    // borderBottom: "1px solid #CBCBCB"
  },
  search: {
    // marginRight: 26,
    '& .MuiOutlinedInput-root': {
      borderRadius: 7,
      margin: 0,
      background: '#F7F7FA',
      width: "280px",
      padding: '0px 30px 0px 30px',
      borderColor: "#333333",
      '&.Mui-focused fieldset': {
        borderColor: '#c4c4c4',
      },
      '&:hover fieldset': {
        borderColor: '#c4c4c4',
      },
    },
  },
  menuOpen: {
    width: `calc(100% - ${DASHBOARD_MENU_WIDTH}px)`,
    transition: `.3s`,
  },
  menuClose: {
    left: 0,
    width: `100%`,
    transition: `.3s`,
  },
  container: {
    // borderBottom: '1px solid #CBCBCB',
    height: '100%',
  },
  dynamicHeader: {
    color: '#333',
    fontWeight: '700',
    fontSize: '26px',
    flex: 1,
  },
  searchIcon: {
    // height: '24px',
    // width: '24px',
    position: 'absolute',
    left: 10,
  },
  hoverSec: {

  },
  adminName: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  notificationCircle: {
    backgroundColor: '#333333',
    padding: '7px',
    position: 'absolute',
    borderRadius: '100%',
    right: '5px',
    top: '5px',
  },
  tabHeader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '33px',
    color: '#1C1C1C',
  }
}));

type Props = {
  showSidebar: Boolean
};
const Topbar = ({ showSidebar }: Props) => {
  const [search, setSearch] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const styles = useStyles();
  const isMenuOpen = useSelector(selectMenuOpen);
  const currentRoute = useSelector(selectCurrentRoute);
  console.log("currentRoute", currentRoute);
  const user = localStorage.getItem('loginResponse')
    ? JSON.parse(localStorage.getItem('loginResponse') || '')
    : '';

  const navigate = useNavigate();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = e => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleLogout = () => {
    localStorage.removeItem('userName');
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('loginResponse');
    navigate('/login', { replace: true });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  return (
    <>
      <Box
        sx={{
          boxShadow: 2, width: showSidebar ? '100%' : '100% !important',
          pl: showSidebar ? '' : '40px !important',
          // position: showSidebar ? '' : 'relative !important',
          left: showSidebar ? '250px' : '0 !important',

        }}
        className={clsx(
          styles.root,
          isMenuOpen ? styles.menuOpen : styles.menuClose,
        )}
      >
        <Box className={styles.wrapper}>
          <Box className={styles.container}>
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent={'space-between'}
              height={'100%'}
            >
              <Typography className={styles.tabHeader} >
                {currentRoute}
              </Typography>
              <Box
                sx={{
                  alignItems: 'left',
                  display: 'flex',
                  flex: 0.5,
                  gap: 5,
                }}
              >
              </Box>
              <TextField
                className={styles.search}
                placeholder={`Search`}
                size="small"
                width={"380px"}
                height={"44px"}
                value={search}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <img
                      src={SearchIcon}
                      alt="search icon"
                      className={styles.searchIcon}
                    />
                  ),
                }}
              />
              <Box
                display="flex"
                flex={1}
                justifyContent={'flex-end'}
                alignItems="center"
              >
                <Avatar
                  sx={{
                    ml: 1,
                    mr: 1,
                    width: 36,
                    height: 36,
                    // borderRadius: '2.7px',
                  }}
                  src={Profile}
                  alt="Profile image"
                />
                <Typography className={styles.adminName}>
                  {(user && user?.fullName) || 'Admin user'}
                </Typography>
                <IconButton onClick={handleClick}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem sx={{ p: 1.5 }} onClick={handleLogout} className={styles.hoverSec}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
};

export default Topbar;
