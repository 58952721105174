import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useStyles } from './styles';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { usePropertySaga } from './redux/saga';
import { sliceKey, reducer } from './redux/slice';
type Props = {};

const PropertyManagement = (props: Props) => {
  const styles = useStyles();
  useInjectReducer({ key: sliceKey, reducer });
  useInjectSaga({ key: sliceKey, saga: usePropertySaga });
  return (
    <Box className={styles.root}>
      <Outlet />
    </Box>
  );
};

export default PropertyManagement;
