import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

export function* getList(action) {
  yield delay(500);
  try {
    const response = {
      items: [
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "userId": "U410142",
          "changeType": "Edit",
          "changeModule": "Payments",
          "valueType": "NA",
          "oldValue": "Paypal",
          "newValue":"Zelle" ,
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "userId": "U411142",
          "changeType": "Delete",
          "changeModule": "Payments",
          "valueType": "Paypal",
          "oldValue": "Paypal",
          "newValue":"NA" ,
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "userId": "U410142",
          "changeType": "Edit",
          "changeModule": "Payments",
          "valueType": "NA",
          "oldValue": "Paypal",
          "newValue":"Zelle" ,
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "userId": "U411142",
          "changeType": "Delete",
          "changeModule": "Payments",
          "valueType": "Paypal",
          "oldValue": "Paypal",
          "newValue":"NA" ,
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "userId": "U410142",
          "changeType": "Edit",
          "changeModule": "Payments",
          "valueType": "NA",
          "oldValue": "Paypal",
          "newValue":"Zelle" ,
        },
        {
          "timeStamp": "07-11-2022, 7:30AM",
          "userId": "U411142",
          "changeType": "Delete",
          "changeModule": "Payments",
          "valueType": "Paypal",
          "oldValue": "Paypal",
          "newValue":"NA" ,
        },
      ],
      meta: {
        totalItems: 5,
        itemCount: 5,
        itemsPerPage: 5,
        totalPages: 5,
        currentPage: 5,
      },
      links: {
        first: '',
        last: '',
        previous: '',
        next: '',
      }
    }
    yield put(actions.getAuditLogsListSuccess(response));
    // console.log(response.items, 'usersList here');
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* useAuditLogsSaga() {
  yield takeLatest(actions.getList.type, getList);
  // yield takeLatest(actions.getAllocatedUsers.type, getAllocatedUsers);
}