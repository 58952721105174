import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    // display:'flex',
    padding: '0px 30px 10px 30px',
    // margin: '0px 30px',
  },
  dateDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
  },
  statusMenu: {
    "& .MuiOutlinedInput-root": {
      background: "#FFF",
      width: '218px',
      borderRadius: "6px",
      marginLeft: "20px",
      height: '48px',
      "&:hover": {
        border: "none",
      },
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E1D9D1",
    },
  },
  dateDivRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 0px 0px 0px'
  },
  page: {
    position: "relative",
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 50,
    marginBottom: 24
  },
  ml25: {
    marginLeft: "25px !important",
  },
  search: {
    background: "#F5F5F5",
    border: "none",
    borderRadius: 7,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  menuItem: {
    // height: '24px',
    fontFamily: 'Roboto',
    // fontStyle: 'normal',
    fontWeight: '400',
    // fontSize: '16px',
    // lineHeight: '24px',
    color: '#8F8E8E',
  },
  cellLink: {
    textDecorationLine: 'underline',
    width: '100%',
    color: '#1D86FF',
    height: '24px',
    lineHeight: '24px',
    fontWeight: '400',
    fontFamily: 'Lato',
    fontSize: '14px',
    textDecoration: 'underline',
    marginLeft: '8px'
  },
  cellText:{
    width: '100%',
    color: '#3D3F47',
    height: '24px',
    lineHeight: '18px',
    fontWeight: '400',
    fontFamily: 'Lato',
    fontSize: '14px',
  },
  dataTableDiv: {
    borderRadius: 6,
    maxHeight: 'calc(100vh - 240px)',
    border: "1px solid #D5D8DE",
    overflowY: "auto",
    marginTop: '14px',
    // borderTop:'0.5px solid #9CA2B0',
    "&::-webkit-scrollbar": {
      width: 4,
      height: 5,
      padding: '5px 0px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#D9D9D9',
      // marginTop:500,
      // boxShadow: 'inset 4px 4px 3px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#1D86FF',
      borderRadius: 20,
      // border: '1px solid #3C4858',
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.15)',
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: '#1D86FF',
    }
  },
  addBtn: {
    fontSize: 14,
    color: "#333333",
    border: '1px solid #D5D8DE',
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  datePicker1: {
    position: 'absolute',
    opacity: 0,
    zIndex: -1,
    right: '200px'
  },
  datePicker: {
    position: 'absolute',
    opacity: 0,
    zIndex: -1
  },
  dateButton: {
    color: '#333333',
    border: '1px solid #D5D8DE',
    backgroundColor: '#F7F7FA',
    minWidth: '180px',
    fontSize: '14px',
    minHeight: '45px'
  },
  bottomFlag: {
    // flex: 1,
    // width: '78px',
    // height: '30px',
    fontFamily: 'Cairo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '30px',
    // display: 'flex',
    alignItems: 'center',
    color: '#1D86FF',
  },
  footer: {
    display: 'block',
  },
  bottomFlagCount: {
    marginLeft: '20px',
  },
  bottomFlagDiv: {
    float: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 8px 5px 16px',
    margin: '20px 0px',
    // width: '131px',
    height: '40px',
    left: '277px',
    top: '744px',
    /* Secondary/Secondary 3 */
    background: '#FFFFFF',
    /* Mains/Main 2 */
    border: '1px solid #C6C9CA',
    borderRadius: '4px',
  },
  plusButton: {
    float: 'right',
    width: '36px',
    height: '36px',
    margin: '20px 0px',
  }
}))