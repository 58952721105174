import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';

export function* getList(action) {
  yield delay(500);
  try {
    const response = {
      items: [
        {
          userId: 'U410142',
          firstName: 'Yan',
          lastName: 'Agron',
          email: 'yan agron@gmail.com',
          userType: 'Tenant',
          mobileNumber: 14842634717,
        },
        {
          userId: 'U410143',
          firstName: 'Matt',
          lastName: 'Smith',
          email: 'Matsmith@gmail.com',
          userType: 'Landlord',
          mobileNumber: 14844731349,
          isActive: true,
        },
        {
          userId: 'U410144',
          firstName: 'Peter',
          lastName: 'Agron',
          email: 'Peteragron@gmail.com',
          userType: 'Tenant',
          mobileNumber: 18143519530,
        },
        {
          userId: 'U410145',
          firstName: 'Suzan',
          lastName: 'Back',
          email: 'Suzanback@gmail.com',
          userType: 'Landlord',
          mobileNumber: 14845211058,
        },
        {
          userId: 'U410146',
          firstName: 'Mils',
          lastName: 'Alcock',
          email: 'Milsalcock@gmail.com',
          userType: 'Tenant',
          mobileNumber: 14845219681,
        },
        {
          userId: 'U410147',
          firstName: 'Suzan',
          lastName: 'Back',
          email: 'SuzanFrost@gmail.com',
          userType: 'Landlord',
          mobileNumber: 14844731151,
        },
        {
          userId: 'U410148',
          firstName: 'Emily',
          lastName: 'Carey',
          email: 'Emilycarey@gmail.com',
          userType: 'Tenant',
          mobileNumber: 14845211058,
          isActive: true,
        },
        {
          userId: 'U410149',
          firstName: 'Eve',
          lastName: 'Best',
          email: 'Evebest@gmail.com',
          userType: 'Landlord',
          mobileNumber: 14844145935,
        },
        {
          userId: 'U410150',
          firstName: 'Buch',
          lastName: 'Kim',
          email: 'SuzanFrost@gmail.com',
          userType: 'Tenant',
          mobileNumber: 16102347184,
        }
      ],
      meta: {
        totalItems: 5,
        itemCount: 5,
        itemsPerPage: 5,
        totalPages: 5,
        currentPage: 5,
      },
      links: {
        first: '',
        last: '',
        previous: '',
        next: '',
      }
    }
    yield put(actions.getUsersListSuccess(response));
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* getPaymentTable(action) {
  yield delay(500);
  try {
    const responseTable = {
      rows: [
        {
          prefferedPaymentMethod: 'Zelle',
          paymentMethodId: '1320456789',
          checked: true,
        },
        {
          prefferedPaymentMethod: 'Venmo',
          paymentMethodId: 'Enter Venmo Mobile Phone',
          checked: false,
        },
        {
          prefferedPaymentMethod: 'Paypal/Buiseness Account',
          paymentMethodId: 'Enter Paypal Email',
          checked: true,
        },
      ],
    };
    yield put(actions.getPaymentTableSuccess(responseTable));
  } catch (e: any) {
    alert("Something Went Wrong in Payment Table");
  }
}

export function* useUserSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getPaymentTable.type, getPaymentTable);

}