/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { AuthPage } from 'app/containers/Auth';
import { userRepoSaga } from 'app/containers/Auth/saga';
import { reducer, sliceKey } from 'app/containers/Auth/slice';

import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import PrivateRoute from 'utils/PrivateRoute';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import LoginForm from './containers/Auth/LoginPage/form';
import DashboardPage from './containers/Dashboard';
import DashboardHome from './containers/Dashboard/components/Home';
import { UnsupportedScreen } from './containers/UnsupportedScreen';

import UserManagement from './containers/Users/components/AllUsers';
import { UserManagementHome } from './containers/Users/components/AllUsers/Loadable';
import PropertyManagement from './containers/PropertyManagement/components/Properties';
import { PropertyManagementHome } from './containers/PropertyManagement/components/Properties/Loadable';

import PrivacyPolicy from './containers/PrivacyPolicy';

import ServiceRequest from './containers/ServiceRequest/components/Requests';
import { ServiceRequestHome } from './containers/ServiceRequest/components/Requests/Loadable';

import PaymentTracker from './containers/PaymentTracker/components/Payments';
import { PaymentTrackerHome } from './containers/PaymentTracker/components/Payments/Loadable';

import AuditLogs from './containers/AuditLogs/components/Logs';
import { AuditLogsHome } from './containers/AuditLogs/components/Logs/Loadable';

import ServiceProvider from './containers/ServiceProvider/components/Providers';
import { ServiceProviderHome } from './containers/ServiceProvider/components/Providers/Loadable';

export function App() {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: userRepoSaga });
    const { width } = useWindowDimensions();
    return (
        <BrowserRouter>
            {(width && width >= 1280) ? (
                <Routes>
                    <Route path="/login/*" element={<AuthPage />}>
                        <Route index element={<LoginForm className="loginText" />} />
                    </Route>
                    <Route
                        path="dashboard"
                        element={
                            <PrivateRoute>
                                <DashboardPage />
                            </PrivateRoute>
                        }
                    >
                        <Route index element={<DashboardHome />} />
                        <Route path="user-management" element={<UserManagement />}>
                            {/* <Route path='user-management/*' element={<PrivetRoutes component={UserManagementRoutes} /> } /> */}

                            <Route path="users/*" element={<UserManagementHome />} />
                            {/* <Route path=":action" element={<UserManagementHome />} />
                  <Route path=":id/:action" element={<UserManagementHome />} /> */}
                            {/* </Route> */}
                        </Route>
                        <Route path="property-management" element={<PropertyManagement />}>
                            <Route path="properties/*" element={<PropertyManagementHome />} />
                        </Route>

                        <Route path="service-provider" element={<ServiceProvider />}>
                            <Route path="providers/*" element={<ServiceProviderHome />} />
                        </Route>

                        <Route path="service-request" element={<ServiceRequest />}>
                            <Route path="requests/*" element={<ServiceRequestHome />} />
                        </Route>

                        <Route path="payment-tracker" element={<PaymentTracker />}>
                            <Route path="payments/*" element={<PaymentTrackerHome />} />
                        </Route>

                        <Route path="audit-logs" element={<AuditLogs />}>
                            <Route path="logs/*" element={<AuditLogsHome />} />
                        </Route>


                    </Route>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            ) : (
                <UnsupportedScreen />
            )}
        </BrowserRouter>
    );
}
