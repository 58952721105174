import { FormElement } from 'app/containers/types';

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type UserListItemType = {

  id: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  userType: string | null;
  mobileNumber: string | null;
  // isActive: boolean;
  // role: string | null;
  // createdAt: string | null;
  // updatedAt: string | null;
  // blockedUntil: string | null;
};
export interface PaymentRow {
  prefferedPaymentMethod: string | null;
  paymentMethodId: string | null;
  checked: Boolean,
}



export interface NewUserRequest {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  userType: string | null;
  mobileNumber: string | null;
  isActive: boolean;
}

export type NewUserResponse = {
  id: any;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  userType: string | null;
  mobileNumber: string | null;
  userStatus: string | null;
  blockedUntil: string | null;
  isActive: true;
  role: any;
  image: any;
  createdAt: string;
  updatedAt: string;
  message?: string;
};

export type UserForm = {
  id: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  email: FormElement;
  userType: FormElement;
  mobileNumber: FormElement;
  isActive: FormElement;
  role: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  blockedUntil: FormElement;
};

export type UserStateType = {
  postsQuery: {
    page: number;
    limit: number;
    q: string;
    status: string;
    orderField: string;
    orderDirection: string;
    userId: string;
  };
  query: {
    limit: number;
    page: number;
    distributorId: string;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: {
    user: UserForm;
  };
  paymentTable: {
    rows: Array<PaymentRow>;
  };
  list: {
    items: Array<UserListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  loading: boolean;
  error: string;
  links: {
    first: string | null,
    previous: string | null,
    next: string | null,
    last: string | null,
  },
};

export const UserState: UserStateType = {
  postsQuery: {
    userId: '',
    page: 1,
    q: '',
    limit: 10,
    orderDirection: '',
    orderField: '',
    status: '',
  },
  query: {
    user_type: '',
    distributorId: '',
    limit: 15,
    page: 1,
    q: '',
    orderField: '',
    orderDirection: '',
    updateMax: '',
    updateMin: '',
    createMax: '',
    createMin: '',
  },
  links: {
    first: '',
    previous: '',
    next: '',
    last: '',
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: '',
      previous: '',
      next: '',
      last: '',
    },
  },
  loading: false,
  form: {
    user: {
      id: { value: '' },
      firstName: { value: '' },
      lastName: { value: '' },
      email: { value: '' },
      userType: { value: '' },
      mobileNumber: { value: '' },
      isActive: { value: '' },
      role: { value: '' },
      createdAt: { value: '' },
      updatedAt: { value: '' },
      blockedUntil: { value: '' },
    },
  },
  error: '',
  paymentTable: {
    rows: [],
  },
};

export type ContainerState = UserStateType;
