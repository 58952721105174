import React, { useEffect, useState } from 'react';
import {
  Typography,
} from '@mui/material';
type Props = {};
const DashboardHome = (props: Props) => {
  const [dateState, setDateState] = useState<
    Array<{ startDate: Date; endDate: Date | null; key: string }>
  >([{ startDate: new Date(), endDate: null, key: 'selection' }]);
  const [dateAnchorElement, setDateAnchorElement] = useState(null);
  const open = Boolean(dateAnchorElement);
  const id = open ? 'date-popover' : undefined;
  // const [stats, setStats] = useState([
  //   {
  //     id: 'purchases',
  //     heading: 'purchase_quantity_stats_text1',
  //     subHeading: 'purchase_quantity_stats_text2',
  //     bg: '#FF0036',
  //     ml: 0,
  //     icon: checklist,
  //     data: { total: 0, close: 0, open: 0 },
  //     child: [
  //       {
  //         title: 'open',
  //         key: 'open',
  //         count: 2000,
  //       },
  //       {
  //         title: 'closed',
  //         key: 'close',
  //         count: 2500,
  //       },
  //       {
  //         title: 'total',
  //         key: 'total',
  //         count: 2500,
  //       },
  //     ],
  //   },
  //   {
  //     id: 'changeRequests',
  //     heading: 'change_request_stats_text1',
  //     subHeading: 'change_request_stats_text2',
  //     ml: 3,
  //     bg: '#1AB1B0',
  //     icon: request,
  //     data: { total: 0, close: 0, open: 0 },
  //     child: [
  //       {
  //         title: 'open',
  //         key: 'open',
  //         count: 0,
  //       },
  //       {
  //         title: 'closed',
  //         key: 'close',
  //         count: 0,
  //       },
  //       {
  //         title: 'total',
  //         key: 'total',
  //         count: 0,
  //       },
  //     ],
  //   },
  //   {
  //     id: 'users',
  //     heading: 'user_stats_text',
  //     subHeading: 'user_stats_text2',
  //     ml: 3,
  //     bg: '#FF7544',
  //     icon: people,
  //     data: { total: 0, last24Hr: 0 },
  //     child: [
  //       {
  //         title: 'connected',
  //         key: 'total',
  //         count: 0,
  //       },
  //       {
  //         title: 'last24hr',
  //         key: 'last24Hr',
  //         count: 0,
  //       },
  //       {
  //         title: 'last24hr',
  //         key: 'last24Hr',
  //         count: 0,
  //       },
  //     ],
  //   },
  //   {
  //     id: 'mobileUsers',
  //     heading: 'app_user_stats_text',
  //     subHeading: 'app_user_stats_text2',
  //     ml: 3,
  //     bg: '#8677FE',
  //     icon: userApp,
  //     data: { total: 0, monthCount: 0, countOf45Days: 0 },
  //     child: [
  //       {
  //         title: 'total',
  //         key: 'total',
  //         count: 0,
  //       },
  //       {
  //         title: 'new_in_30_days',
  //         key: 'monthCount',
  //         count: 0,
  //       },
  //       {
  //         title: 'new_in_45_days',
  //         key: 'countOf45Days',
  //         count: 0,
  //       },
  //     ],
  //   },
  // ]);

  useEffect(() => {
    if (dateState && dateState[0]) {
      console.log(dateState);
    }
  }, [dateState]);


  return (
    <>
      <Typography sx={{
        display: 'flex', justifyContent: 'center', fontWeight: '800', padding: '50px', fontSize: '40px'
      }} className='classes.welcome'>Welcome to SRE-Managment</Typography>
    </>
  );
};

export default DashboardHome;
