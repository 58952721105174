/**
 * Gets the repositories of the user from Github
 */

import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
// import { LoginResponse } from 'types/LoginResponse';
// import { API_URL } from 'utils/constants';
import { selectLoginForm } from './selectors';
import { actions, logoutSuccess } from './slice';
import { LoginErrorType } from './types';

export function* logoutRequest() {
  yield delay(500);
  yield localStorage.setItem('sessionToken', '');
  yield put(logoutSuccess());
}

export function* loginRequest(action) {
  yield delay(500);
  // Select username from store
  const form = yield select(selectLoginForm);
  if (form.username.length === 0) {
    yield put(actions.loginError(LoginErrorType.USERNAME_EMPTY));
    return;
  }
  if (form.password.length === 0) {
    yield put(actions.loginError(LoginErrorType.PASSWORD_EMPTY));
    return;
  }
  if (form.username.value === 'admin@sre.com' && form.password.value === '1234') {
    yield localStorage.setItem('sessionToken', 'token');
    yield localStorage.setItem('loginResponse', JSON.stringify({}));
    yield put(actions.loginSuccess({} as any));
    yield call(action.payload?.callback);
    return
  }else{
    yield put(actions.loginError(LoginErrorType.USER_NOT_FOUND));
    return;
  }
  // const options = {
  //   method: 'POST',
  //   body: JSON.stringify({
  //     email: form.username.value,
  //     password: form.password.value,
  //   }),
  // };
  // try {
  //   // Call our request helper (see 'utils/request')
  //   const response = yield request(`${API_URL}/auth/login`, options);
  //   console.log(response);

  //   if (response && response.message === 'LOGIN_FAILED') {
  //     yield put(actions.loginError('Incorrect email and password'));
  //     return false;
  //   }
  //   if (response && response.message !== 'MESSAGE.USER.LOGIN_SUCCESS') {
  //     yield put(actions.loginError(response.message));
  //     return false;
  //   }
  //   yield localStorage.setItem('sessionToken', response.token);
  //   yield localStorage.setItem('loginResponse', JSON.stringify(response));
  //   yield put(actions.loginSuccess(response));
  //   yield call(action.payload?.callback);
  // } catch (err: any) {
  //   console.log(err);
  // }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userRepoSaga() {
  yield takeLatest(actions.login.type, loginRequest);
  yield takeLatest(actions.logout.type, logoutRequest);
}
